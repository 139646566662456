import JSEncrypt from "jsencrypt";
import { getRsaPublicKey } from "@/api/organ";
export default {
    data() {
        return {
            PUBLIC_KEY: null,
        }
    },
    methods: {
        encryption(value) {
            if (this.PUBLIC_KEY) {
                let encrypt = new JSEncrypt();
                encrypt.setPublicKey(
                    "-----BEGIN PUBLIC KEY-----" +
                    this.PUBLIC_KEY +
                    "-----END PUBLIC KEY-----"
                );
                let encrypted = encrypt.encrypt(this.$base64.encode(value));
                // console.log("加密后数据:%o", encrypted);
                return encrypted;
            } else {
                console.log("PUBLIC_KEY:null");
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            getRsaPublicKey().then(res => {
                this.PUBLIC_KEY = res.data.pub_secret;
            });
        });
    }
}